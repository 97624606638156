<!--
 * @Author: yhm 1913238521@qq.com
 * @Date: 2023-11-13 16:20:48
 * @LastEditors: yhm 1913238521@qq.com
 * @LastEditTime: 2023-12-21 14:59:01
 * @FilePath: \nestjs-demoe:\project\vue-template\vue-material-dashboard-master\src\pages\peopleResult\index.vue
 * @Description: 
-->
<template>
	<div class="user">
		<div class="md-layout" v-loading="loading">
			<div class="search-container head-shadow">
				<div class="search-people">
					<div class="search-form">
						<span class="search-form-text search-form-title">找 人</span><el-input class="search-input"
							v-model="searchParmas.name" placeholder="请输入姓名"></el-input><span
							class="search-form-text">和</span>
						<el-input class="search-input" v-model="searchParmas.idCard" placeholder="请输入身份证号码"></el-input><span
							class="search-form-text">和</span>
						<el-input class="search-input" v-model="searchParmas.mobile" placeholder="请输入手机号"></el-input><span
							class="search-form-text">和</span>
						<el-input class="search-input" v-model="searchParmas.carNumber" placeholder="请输入车牌号"></el-input>
					</div>
					<div class="search-btns">
						<md-button class="query-btn md-success" @click="searchList">
							<i class="el-icon-zoom-in el-icon"></i>
							查 询
						</md-button>
						<md-button class="rest-btn md-default" @click="clearSearch">
							<i class="el-icon-refresh-right el-icon"></i>
							重 置
						</md-button>
					</div>
				</div>
			</div>

			<div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100 mt-20">
				<md-card class="md-card-plain">
					<md-card-header class="card-header" md-alignment="center">
						<!-- <h4 class="title">查询结果</h4> -->
						<!-- <p class="category"> -->
						<!-- <el-button type="success" @click="addUser">添加人员</el-button> -->
						<!-- </p> -->
					</md-card-header>
					<md-card-content class="card-content">
						<el-table :data="tableData">
							<el-table-column type="index" align="center" label="编号" width="100">
							</el-table-column>
							<el-table-column prop="name" label="姓名" min-width="120">
							</el-table-column>
							<el-table-column prop="address" label="住址" min-width="120">
							</el-table-column>
							<el-table-column prop="sex" label="性别" min-width="120">
								<template slot-scope="scope">
									{{ sexEnum[scope.row.sex] || '-' }}
								</template>
							</el-table-column>
							<el-table-column prop="mobile" label="手机号码" min-width="120">
								<template slot-scope="scope">
									{{ scope.row.mobile || '-' }}
								</template>
							</el-table-column>
							<el-table-column prop="relation" label="户主关系" min-width="120">
								<template slot-scope="scope">{{ relationEnum[scope.row.relation] || '-' }}</template>
							</el-table-column>
							<el-table-column prop="lstatus" label="居住状态" min-width="120">
								<template slot-scope="scope">{{ lstatusEnum[scope.row.lstatus] }}</template>
							</el-table-column>
							<el-table-column prop="phone" label="人员类型" min-width="220">
								<template slot-scope="scope">
									<template v-for="(item, index) in tags">
										<el-tag class="mr-5 mb-5"
											v-if="scope.row.tags && scope.row.tags.indexOf(item.code) > -1" :key="item.code"
											effect="plain">
											{{ item.name }}
										</el-tag>
									</template>
									<!-- tags -->
									<!-- <el-switch @change="(v) => changeStatus(v, scope.row)" v-model="scope.row.status"
										:active-value="1" :inactive-value="0" active-color="#13ce66"></el-switch> -->
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="240">
								<template slot-scope="scope">
									<el-button type="text" @click="editUser(scope.row)">
										编辑
									</el-button>
									<el-button type="text" @click="delPeople(scope.row)">
										删除
									</el-button>
									<!-- <el-popconfirm @confirm="delPeople(scope.row)" confirm-button-text='确认'
										cancel-button-text='取消' icon="el-icon-info" icon-color="red"
										:title="`确认永久删除 ${scope.row.name} 吗？`">
										<el-button slot="reference" class="del-user" type="text">删除</el-button>
									</el-popconfirm> -->
								</template>
							</el-table-column>
						</el-table>
						<el-pagination class="page" layout="total, prev, pager, next" :current-page="page.pageNum"
							@current-change="updatePagination" :page-size="page.pageSize" :total="page.total">
						</el-pagination>
						<!-- <pagination ref="pagination" :total="page.total" @nextPage="updatePagination" /> -->
						<!-- <el-pagination class="page" layout="total, prev, pager, next" :current-page="1"
							@current-change="updatePagination" :page-size="12" :total="1000">
						</el-pagination> -->
					</md-card-content>
				</md-card>
			</div>
		</div>
		<!-- 当前人删除 -->
		<el-dialog title="当前人删除" :visible.sync="popleDeleteVisible" width="400px" :before-close="peopleDeleteClose">
			<el-form ref="peopleDeleteForm" :rules="popleDeleteFormRules" label-width="80px" :model="popleDeleteForm">
				<el-form-item label="删除原因" prop="type">
					<el-select class="" v-model="popleDeleteForm.type">
						<el-option value="1" label="搬离"></el-option>
						<el-option value="2" label="离世"></el-option>
						<el-option value="3" label="信息有误"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="peopleDeleteClose">取 消</el-button>
				<el-button type="success" @click="peopleDeleteSub">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
// import { SimpleTable, OrderedTable } from "@/components";
import * as API from '@/api/peopleResult.js'
import { getTags, delPerson } from '@/api/household.js'
// import Pagination from '@/components/pagination/index.vue'
import { formatDate } from '@/utils/assist.js'
import { relationEnum, sexEnum, lstatusEnum } from '@/enum/index.js'
export default {
	components: {
		// Pagination
	},
	data () {
		return {
			popleDeleteVisible: false,
			sexEnum: sexEnum,
			relationEnum: relationEnum,
			lstatusEnum: lstatusEnum,
			tags: [],
			roleInfo: [],
			areaList: [],
			form: {},
			ownerForm: [],
			labelPosition: 'rigin',
			userForm: {
				area: '',
				bding: '',
				tags: [],
				buyers: [],
				unit: '',
				hpropty: '',
				hnum: '',
				hremark: ''
			},
			searchParmas: {
				carNumber: '',
				idCard: '',
				mobile: '',
				name: ''
			},
			dialogVisible: false,
			roleDialogVisible: false,
			dialogFormTitle: '户信息添加',
			tableHeaderColor: '',
			tableData: [],
			paginatedUsers: [],
			loading: false,
			btnLoading: false,
			page: {
				total: 0,
				pageNum: 1,
				pageSize: 10
			},
			roleList: [],
			currentUserId: '',
			workLimitList: [],
			popleDeleteForm: {
				type: ''
			},
			popleDeleteFormRules: {
				type: [
					{
						required: true,
						message: ' 请选择删除原因',
						trigger: 'change'
					}
				],
			},
			currentId: ''
		}
	},
	created () {
		const query = this.$route.query
		Object.keys(this.searchParmas).forEach(key => {
			if (query[key] != '' || query[key] != undefined) {
				this.searchParmas[key] = query[key]
			}
		})
		this.getPeopleList()
		this.getTags()
	},
	methods: {
		peopleDeleteSub () {
			this.$refs.peopleDeleteForm.validate(async valid => {
				if (valid) {
					const data = await delPerson({
						id: this.currentId,
						delReason: this.popleDeleteForm.type - 0
					})

					if (data.code === 200) {
						this.$notify.success({
							title: '提示',
							message: '删除成功'
						})
						this.peopleDeleteClose()
						this.getPeopleList()
					}
				}
			})
		},
		peopleDeleteClose () {
			this.popleDeleteVisible = false
			this.popleDeleteForm.type = ''
			this.$nextTick(() => {
				this.$refs.peopleDeleteForm.clearValidate()
			})
		},
		/**
		 * 获取列表
		 */
		async getPeopleList () {
			this.loading = true
			try {
				const { pageSize, pageNum } = this.page
				const data = await API.getPeopleList({
					pageNum,
					pageSize,
					...this.searchParmas
				})
				if (data.code === 200 && data.data) {
					this.tableData = data.data.list || []
					this.page.total = data.data.total
				}
				this.loading = false
			} catch (error) {
				this.loading = false
				console.error(error)
			}
		},
		/**
		 * 修改用户状态
		 */
		async changeStatus (val, { id, nickName }) {
			try {
				const data = await API.updateStatus({ status: val, id })
				if (data.code === 200) {
					const message = `已成功${val === 1 ? '启用' : '关闭'} ${nickName} 用户！`
					this.$notify.success({
						title: '提示',
						message
					})
				}
			} catch (error) {
				console.error(error)
			}
		},
		async getTags () {
			const data = await getTags()

			if (data.code === 200) {
				this.tags = data.data || []
			}
		},
		/**
		 * 人员删除
		 */
		delPeople ({ id }) {
			this.currentId = id
			this.popleDeleteVisible = true
		},
		// subUser () {
		// 	this.$refs.dialogForm.validate(validator => {
		// 		if (!validator) return
		// 		if (this.dialogFormTitle === '户信息添加') {
		// 			// this.addHouse()
		// 		} else {
		// 			this.updateHouse()
		// 		}
		// 	})
		// },
		// handleClose () { },
		addUser () {
			this.dialogFormTitle = '户信息添加'
			this.dialogVisible = true
		},
		editUser ({ hid, id }) {
			this.$router.push({
				path: '/household',
				query: {
					rid: id,
					hid
				}
			})
			// this.currentUserId = id
			// this.dialogFormTitle = '户信息编辑'
			// this.getHouseInfo()
			// this.dialogVisible = true
		},
		searchList () {
			this.page.pageNum = 1
			this.getPeopleList()
		},
		clearSearch () {
			this.searchParmas = {
				carNumber: '',
				idCard: '',
				mobile: '',
				name: ''
			}
			this.searchList()
		},
		/**
		 * 分页翻动
		 */
		updatePagination (page, pageSize, sort, sortOrder) {
			console.log('pagination has updated', page, pageSize, sort, sortOrder);
			this.page.pageNum = page
			this.getPeopleList()
		},
		formatDate (dateStr) {
			if (!dateStr) return '-'
			return formatDate(new Date(dateStr).getTime(), 'YY-MM-DD hh:mm')
		},
	},
}
</script>

<style lang="scss" scoped>
.card-content {
	background-color: #fff;
}

.auth-select {
	width: 100%;
}

.page {
	margin-top: 20px;
	text-align: right;
}

.del-user {
	padding-left: 12px;
}

.card-header {
	text-align: right;
	background-color: #eee;
}

.search-container {
	margin: 0px 15px 0;
	width: 100%;
	border-radius: 2px;
	// background: linear-gradient(180deg, #43D49C 3%, #14A874 99%);
	background-color: #fff;
	font-size: 16px;

	.search-people {
		display: flex;
		padding: 10px 15px;

		.search-form {
			display: flex;
			align-items: center;
			// flex: 1;
			color: #000;

			&-text {
				padding: 0 15px;
				min-width: 40px;
				font-size: 14px;
			}

			&-title {
				min-width: 80px;
				font-size: 16px;
			}
		}
	}

	.search-household {
		padding: 0px 15px 10px;
	}

	.search-btns {
		margin-left: 15px;
		margin-right: 2%;
		font-size: 18px;

		.rest-btn {
			margin-left: 20px;
		}

		.el-icon {
			margin-right: 8px;
		}

		::v-deep .md-button .md-button-content {
			font-size: 16px;
		}
	}

	.search-household-input {
		margin-right: 24px;
	}

	.search-input {
		max-width: 150px;
		// height: 32px;

		// ::v-deep .el-input__inner {
		// 	height: 32px;
		// 	line-height: 32px;
		// }
	}
}

.owner-form {
	position: relative;
	margin-top: 10px;
	padding: 30px 30px 20px 15px;
	border-radius: 2px;
	border: 1px solid #e6e6e6;

	.close {
		position: absolute;
		right: 5px;
		top: 5px;
		padding: 5px;

		&:hover {
			cursor: pointer;
			opacity: .7;
		}
	}
}
</style>